import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0b499a",
        secondary: "#0e6eb7",
        accent: "#fff",
      },
    },
  },
});
